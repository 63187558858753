export const pages = [
  {
    link: '/experts',
    name: 'Эксперты'
  },
  {
    link: '/artists',
    name: 'Музыканты'
  },
  // {
  //   link: '/headliners',
  //   name: 'Хедлайнеры'
  // },
  // {
  //   link: '/program',
  //   name: 'Программа'
  // },
  {
    link: '/program',
    name: 'Программа'
  },
  {
    link: '/contacts',
    name: 'Контакты'
  },
  {
    link: '/faq',
    name: 'FAQ'
  },
  {
    link: '/results',
    name: 'Итоги'
  }
  // {
  //   link: '/broadcasts',
  //   name: 'Трансляции'
  // },
]

export const mobilePages = [
  {
    link: '/program',
    name: 'Программа'
  },
  {
    link: '/lectures',
    name: 'Лекторий'
  },
  {
    link: '/artists',
    name: 'Музыканты'
  },
  {
    link: '/experts',
    name: 'Эксперты'
  },
  // {
  //   link: '/headliners',
  //   name: 'Хедлайнеры'
  // },
  // {
  //   link: '/broadcasts',
  //   name: 'Трансляции'
  // },
  {
    link: '/results',
    name: 'Итоги'
  },
  {
    link: '/faq',
    name: 'FAQ'
  },
  {
    link: '/contacts',
    name: 'Контакты'
  }
]
