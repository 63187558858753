import styled, { css } from 'styled-components'
import Colors from 'constants/colors'
import Threshold from 'constants/threshold'

export interface ButtonProps {
  big?: boolean
  green?: boolean
  disabled?: boolean
  submit?: boolean
  href?: string
  link?: string
  target?: string
  rel?: string
  style?: any
  isOutlined?: boolean
  isAnimated?: boolean
}

export const ButtonStyled = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 40px;
  min-width: 260px;
  /* max-width: 305px; */
  width: 100%;
  height: 100%;
  border-radius: 2px;
  transition: all 0.3s ease-out;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 9px;
  outline: none;
  border: none;
  text-decoration: none;
  background-color: ${Colors.turquoise};
  color: ${Colors.white};

  appearance: none;

  @media (min-width: ${Threshold.phone}) {
    min-width: 305px;
    min-height: 54px;
    padding: 12px;
    font-size: 20px;
    line-height: 25px;
  }

  :hover {
    opacity: 50%;
  }

  ${props =>
    props.big &&
    css`
      color: ${Colors.black};
      background-color: ${Colors.yellow};
      width: 260px;
      height: 40px;

      @media (min-width: ${Threshold.phone}) {
        min-width: 380px;
        min-height: 70px;
        padding: 24px 22px;
      }
    `}

  ${props =>
    props.green && 
    css`
      color: ${Colors.white};
      background-color: ${Colors.turquoise};
      width: 260px;
      height: 40px;

      @media (min-width: ${Threshold.phone}) {
        min-width: 380px;
        min-height: 70px;
        padding: 24px 22px;
      }
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: default;
      color: ${Colors.white};
      background-color: ${Colors.lightGrey};

      :hover,
      :focus,
      :active {
        opacity: 50%;
      }
    `}

    ${props =>
    props.isOutlined &&
    css`
      color: ${Colors.yellow};
      background-color: initial;
      border: 2px solid ${Colors.yellow};
      width: 260px;
      height: 40px;

      @media (min-width: ${Threshold.phone}) {
        min-width: 380px;
        min-height: 70px;
        padding: 24px 22px;
      }
    `}

    ${props =>
    props.isAnimated &&
    css`
      animation: glow 1.3s infinite;

      &:hover {
        animation: none;
      }
    `}

    @keyframes glow {
    0% {
      box-shadow: 5px 5px 20px rgba(250, 234, 10, 1), -5px -5px 20px rgba(250, 234, 10, 1);
    }

    50% {
      box-shadow: 5px 5px 15px rgba(250, 234, 10, 0.3), -5px -5px 15px rgba(250, 234, 10, 0.3);
    }

    100% {
      box-shadow: 5px 5px 20px rgba(250, 234, 10, 1), -5px -5px 20px rgba(250, 234, 10, 1);
    }
  }
`
