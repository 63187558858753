import styled from 'styled-components'

import Threshold from 'constants/threshold'
import Colors from 'constants/colors'
import { ReactSVG } from 'react-svg'
import Link from 'next/link'

export const SocialIconLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer'
})`
  display: inline-block;
  transition-duration: 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const MainLogo = styled.img`
  display: block;
  margin-bottom: 30px;
  transition-duration: 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  @media (min-width: ${Threshold.phone}) {
    margin-bottom: 17px;
  }
`

export const DoubletappLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer'
})`
  display: flex;
  margin-bottom: 30px;
  border-bottom: 2px solid rgb(255, 255, 255);
  cursor: pointer;
  transition: all 0.2s ease 0s;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.black};

  & > * {
    vertical-align: middle;
  }

  &:hover {
    border-bottom: 2px solid ${Colors.red};
  }

  @media (min-width: ${Threshold.phone}) {
    margin-bottom: 0;
    img {
      width: 150px;
      height: 14px;
    }
  }
`

export const SocialIconsMobileContainer = styled.div`
  display: block;
  margin-bottom: 30px;

  @media (min-width: ${Threshold.phone}) {
    display: none;
  }
`

export const FooterContainer = styled.footer`
  overflow-x: 'hidden';
  margin-top: auto;
  width: 100vw;
  position: relative;
  display: grid;
  padding: 48px 0 18px;

  @media (min-width: ${Threshold.phone}) {
    grid-template-columns: 1fr 160px 1fr;
    padding: 30px 0;
  }
`

export const About = styled.div`
  width: 74%;
  max-width: 440px;
  margin: auto;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
`

export const AboutContainer = styled.div`
  width: 100%;
  order: 2;
  text-align: center;
  margin-bottom: 30px;

  @media (min-width: ${Threshold.phone}) {
    order: 1;
    text-align: left;
    margin: auto;
  }
`

export const MainContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${Threshold.phone}) {
    order: 2;
  }
`
export const SvgLogoLink = styled(Link)`
  /* order: 1; */
`
export const LogoLink = styled.a`
  margin-bottom: 30px;
  @media (min-width: ${Threshold.phone}) {
    margin-bottom: 17px;
  }
  /* order: 1; */
`
export const SvgLogo = styled(ReactSVG)`
  width: 150px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
`

export const SocialIconsContainer = styled.div`
  margin: auto;
  display: none;

  @media (min-width: ${Threshold.phone}) {
    display: block;
    order: 3;
  }
`
