import Link from 'next/link'
import React from 'react'

import { ButtonStyled } from './styles'

export interface ButtonProps {
  big?: boolean
  green?: boolean
  disabled?: boolean
  submit?: boolean
  href?: string
  link?: string
  target?: string
  rel?: string
  style?: any
  onClick?: (event: React.SyntheticEvent<HTMLElement>) => void
  isOutlined?: boolean
  isAnimated?: boolean
  className?: string
}

const Button: React.FC<ButtonProps> = ({
  children,
  submit,
  href,
  link,
  target,
  rel,
  style,
  onClick,
  disabled,
  ...rest
}) => {

  return (
    <ButtonStyled
      type={submit ? 'submit' : 'button'}
      as={href ? 'a' : 'button'}
      {...rest}
      href={href}
      target={target}
      rel={rel}
      onClick={event => !disabled && onClick && onClick(event)}
    >
      {children}
    </ButtonStyled>
  )
}

export default Button
