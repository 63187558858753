import React, { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { mobilePages, pages } from 'constants/pages'

import * as SC from './styles'
import { useOutsideClick } from 'hooks/useOutsideClick'

const Header = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const dropdownMenu = React.useRef<null>(null)

  useOutsideClick(dropdownMenu, () => {
    setIsSidebarOpen(false)
  })
  const { pathname } = useRouter()
  const toggleSideBar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <>
      <SC.HeaderContainer ref={dropdownMenu} active={isSidebarOpen}>
        <SC.Links>
          
          
          {/* TODO: открыть, когда надо будет открыть меню   */}
            <SC.BurgerMenuButtonWrapper onClick={toggleSideBar}>
            <SC.BurgerMenuButton active={isSidebarOpen} />
          </SC.BurgerMenuButtonWrapper>

          {pages.slice(0, 4).map((page, i) => (
            <SC.LinkContainer
              order={i > 2 ? i + 1 : i}
              selected={page.link === pathname || (page.link.length > 1 && pathname.startsWith(page.link + '/'))}
              key={i}
              active={isSidebarOpen}
            >
              <Link href={page.link} shallow>
                <a>{page.name}</a>
              </Link>
            </SC.LinkContainer>
          ))}
          <SC.SvgLogoLink href="/" shallow>
            <SC.LogoLink>
              <SC.SvgLogo src="/svgs/newOpen.svg" width="180px" alt="new open" />
            </SC.LogoLink>
          </SC.SvgLogoLink>
          <a href={process.env.NEXT_PUBLIC_ADDRESS_CRM} style={{ order: 8 }} target="_blank" rel="noopener noreferrer">
            <img src="/svgs/avatar.svg" width="40px" alt="login" />
          </a>
        </SC.Links>
      </SC.HeaderContainer>
      <SC.SideBarContainter active={isSidebarOpen}>
        <SC.SidebarLinks>
          {mobilePages?.map((page, i) => (
            <SC.SidebarLinkContainer
              order={i > 2 ? i + 1 : i}
              selected={page.link === pathname || (page.link.length > 1 && pathname.startsWith(page.link + '/'))}
              key={i}
              active={isSidebarOpen}
              lineNumber={(i % 5) * 100}
            >
              <Link href={page.link} shallow>
                <SC.SidebarPageName>{page.name}</SC.SidebarPageName>
              </Link>
            </SC.SidebarLinkContainer>
          ))}
        </SC.SidebarLinks>
      </SC.SideBarContainter>
    </>
  )
}

export default Header
