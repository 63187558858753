import styled from 'styled-components'

export const Container = styled.div<{ disabled: boolean }>`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  background-color: ${props => (props.disabled ? 'grey' : 'transparent')};

  * {
    filter: ${props => (props.disabled ? 'brightness(0.85)' : 'none')};
  }
`

export const Content = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
