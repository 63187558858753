import Threshold from "constants/threshold";
import styled from "styled-components";

import Colors from 'constants/colors'

export const MobileMenuHeaderWrapper = styled.div`
  z-index: 13;
  position: sticky;
  -webkit-tap-highlight-color: transparent;
  top: 0px;
  margin-top: -90px;
  @media (min-width: ${Threshold.tablet}) {
    display: none;
  }
`

export const MobileMenuButton = styled.img`
  position: absolute;
  top: 37px;
  right: 25px;
  cursor: pointer;

  @media (min-width: ${Threshold.tablet}) {
    display: none;
  }
`

export const SideBarContainer = styled.div<{ active: boolean, top: number }>`
  position: absolute;
  width: 100vw;
  will-change: transform;
  transform: translateX(${props => props.active ? 0 : '-100vw'});
  /* top:  ${props => props.active ? 0 : '-300vh'}; */
  padding: 56px 0 38px;
  border-radius: 0 0 15px 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  background-color: ${Colors.turquoise};
  z-index: 11;

  transition: all 300ms ease-out;
`

export const ImageContainer = styled.div<{isShowBackground: boolean}>`
  position: sticky;
  width: 40px;
  height: 90px;
  /* top: 57px; */
  left: calc(100vw - 65px);
  cursor: pointer;
  z-index: 10;
  transition: all ease-out 0.3s;
  background: ${props => props.isShowBackground ? 'white' : 'transparent'};
  width: 100vw;

  svg {
    top: 37px;
    left: calc(100vw - 65px);
    position: absolute;

    /* rect {
      fill: blac;
    } */

    rect {
      fill: ${props => props.isShowBackground ? 'black' : 'white'} ;
      stroke: ${props => props.isShowBackground ? 'black' : 'white'}; 
    }
  }

  @media (min-width: ${Threshold.tablet}) {
    display: none;
  }
`

export const LinksContainer = styled.div`
  margin: 43px 0 43px 35px;
  /* height: 250px; */
  height: 200px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  flex-wrap: wrap;

  & > a {
    color: ${Colors.white};
    text-decoration: none;
    display: block;
    font-weight: bold;
    font-size: 20px;
  }

  & > a:not(:last-child) {
    margin-bottom: 25px;
  }

  & > a:hover {
    opacity: 0.4;
  }
`

export const SidebarLogo = styled.img`
  position: relative;
  top: -15px;
  left: 35px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`


