/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useEffect, useState } from 'react'


function useScrollSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [scrollSize, setScroll] = useState<{ scrollY: number | undefined }>({
    scrollY: undefined
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleScroll() {
      // Set window width/height to state
      setScroll({
        scrollY: window.scrollY
      })
    }

    window.addEventListener('scroll', handleScroll)

    handleScroll()

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return scrollSize
}

export default useScrollSize
