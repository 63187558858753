import React from 'react'
import Link from 'next/link'

import {
  About,
  AboutContainer,
  DoubletappLink,
  FooterContainer,
  MainContainer,
  SocialIconsMobileContainer,
  MainLogo,
  SocialIconLink,
  SocialIconsContainer,
  LogoLink,
  SvgLogoLink,
  SvgLogo
} from './styles'

const SocialIcons = () => (
  <div>
    <SocialIconLink href="https://vk.com/newopenshowcase">
      <img src={'/svgs/vk.svg'} alt="vkontakte" width="30px" />
    </SocialIconLink>
    {/* <SocialIconLink href="https://www.instagram.com/newopenshowcase/">
      <img src={'/svgs/instagram.svg'} alt="instagram" width="30px" />
    </SocialIconLink>
    <SocialIconLink href="https://www.facebook.com/newopenshowcase/about/?ref=page_internal">
      <img src={'/svgs/facebook.svg'} alt="facebook" width="30px" />
    </SocialIconLink> */}
  </div>
)

const Footer = () => (
  <FooterContainer>
    <AboutContainer>
      <About>
      Проект реализуется при поддержке Президентского фонда культурных инициатив <br />
      Проект проходит при поддержке Федерального агентства по делам молодежи
      </About>
    </AboutContainer>
    <MainContainer>
      <SvgLogoLink href="/" shallow>
        <LogoLink>
          <SvgLogo src="/svgs/newOpen.svg" width="150px" alt="new open" />
        </LogoLink>
      </SvgLogoLink>
      <SocialIconsMobileContainer>
        <SocialIcons />
      </SocialIconsMobileContainer>
      <DoubletappLink href="https://doubletapp.ai">Сделано в Doubletapp</DoubletappLink>
    </MainContainer>
    <SocialIconsContainer>
      <SocialIcons />
    </SocialIconsContainer>
  </FooterContainer>
)

export default Footer
