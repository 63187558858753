import { mobilePages } from 'constants/pages'
import useOutsideClick from 'hooks/useOutsideClick'
import useScrollSize from 'hooks/useScrollSize'
import Link from 'next/link'
import React from 'react'
import Button from '../Button'

import * as SC from './styled'



const index = ({ isSidebarOpen, setIsSidebarOpen, menuButtonColor}) => {
  const dropdownMenu = React.useRef<null>(null)
  const scroll = useScrollSize()

  useOutsideClick(dropdownMenu, () => {
    setIsSidebarOpen(false)
  })
  
  return (
    <SC.MobileMenuHeaderWrapper ref={dropdownMenu}>

      
      <SC.SideBarContainer active={isSidebarOpen} top={scroll.scrollY}>
      <Link href="/" shallow>
        <SC.SidebarLogo src="/svgs/newOpenLight.svg" alt="new open" />
      </Link>
      <SC.MobileMenuButton src="/svgs/close.svg" onClick={() => setIsSidebarOpen(false)} />
      <SC.LinksContainer>
        {mobilePages?.map((page, i) => (
          <Link href={page.link} key={i}>
            <a>{page.name}</a>
          </Link>
        ))}
      </SC.LinksContainer>
      <SC.ButtonContainer>
        <Button big href={process.env.NEXT_PUBLIC_ADDRESS_CRM} target="_blank" rel="noopener noreferrer">
          Личный кабинет
        </Button>
      </SC.ButtonContainer>
    </SC.SideBarContainer>

    <SC.ImageContainer onClick={() => setIsSidebarOpen(true)} isShowBackground={scroll.scrollY > 200 || menuButtonColor === 'black'}>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect
            x="4.11424"
            y="8.1143"
            width="32.5714"
            height="1.82857"
            rx="0.914286"
            fill={menuButtonColor}
            stroke={menuButtonColor}
            strokeWidth="1.82857"
          />
          <rect
            x="4.11424"
            y="19.0857"
            width="32.5714"
            height="1.82857"
            rx="0.914285"
            fill={menuButtonColor}
            stroke={menuButtonColor}
            strokeWidth="1.82857"
          />
          <rect
            x="4.11424"
            y="30.0572"
            width="32.5714"
            height="1.82857"
            rx="0.914286"
            fill={menuButtonColor}
            stroke={menuButtonColor}
            strokeWidth="1.82857"
          />
        </svg>
      </SC.ImageContainer>
    </SC.MobileMenuHeaderWrapper>
  )
}

export default index
