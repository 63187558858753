import React, { useState } from 'react'
import Header from 'components/common/Header'
import Footer from 'components/common/Footer'
import { Container, Content } from './styles'
import useWindowSize from 'hooks/useWindowSize'
import MobileHeader from '../MobileHeader'
import Threshold from 'constants/threshold'

interface Props {
  children: JSX.Element
  meta?: {},
  menuButtonColor?: 'white' | 'black'
}

const Layout = ({ children, meta = {}, menuButtonColor = 'white' }: Props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false)
  const windowSize = useWindowSize()  

  React.useEffect(() => {
    const html = document.querySelector('html')
    const body = document.querySelector('body')

    if (windowSize.width > parseInt(Threshold.tablet)) {
      setIsMobileSidebarOpen(false)
      html.style.overflowY = isSidebarOpen ? 'hidden' : 'visible'

    }

    if (windowSize.width < parseInt(Threshold.tablet)) {
      html.style.overflow = 'visible'
      setIsSidebarOpen(false)

      body.style.overflowY = isMobileSidebarOpen ? 'hidden' : 'visible'
    }
  }, [isSidebarOpen, windowSize.width, isMobileSidebarOpen])

  return (
    <>
      <MobileHeader
        menuButtonColor={menuButtonColor}
        isSidebarOpen={isMobileSidebarOpen}
        setIsSidebarOpen={setIsMobileSidebarOpen}
      />
      <Container disabled={isMobileSidebarOpen}>
        <Content id="content">
          <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
          {children}
          <Footer />
        </Content>
      </Container>
    </>
  )
}

export default Layout
