import styled from 'styled-components'

import Threshold from 'constants/threshold'
import Colors from 'constants/colors'
import { ReactSVG } from 'react-svg'
import Link from 'next/link'

export const HeaderContainer = styled.header<{ active: boolean }>`
  display: none;
  height: 100px;
  box-shadow: ${props => (props.active ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.1)')};
  transition: box-shadow 300ms ease-in-out;
  position: relative;
  z-index: 100;

  @media (min-width: ${Threshold.tablet}) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: ${Colors.white};
    img {
      cursor: pointer;
    }

    img:hover {
      transition-duration: 200ms;
      transform: scale(1.04);
    }
  }
`
export const SvgLogoLink = styled(Link)`
  order: 1;
`
export const LogoLink = styled.a`
  order: 1;
`
export const SvgLogo = styled(ReactSVG)`
  width: 180px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;

    :hover {
      .red {
        transform-origin: bottom left;
        /* transition: transform 1s ease; */
        animation: rotatingRed 1s linear infinite;
        animation-direction: normal;
        transform-box: fill-box;
      }
      .yellow {
        transform-origin: 50% 50%;
        animation: rotatingYellow 2.5s linear infinite;
        transform-box: fill-box;
      }
      .blue {
        transform-origin: 50% 50%;
        animation: rotatingBlue 2.5s linear infinite;
        animation-direction: normal;
        transform-box: fill-box;
      }
    }

    @keyframes rotatingYellow {
      from {
        transform: rotate(0deg) scale(1.2);
      }
      to {
        transform: rotate(360deg) scale(1.2);
      }
    }

    @keyframes rotatingRed {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(-22deg);
      }
      75% {
        transform: rotate(22deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }

    @keyframes rotatingBlue {
      0% {
        transform: translateX(0);
      }
      30% {
        transform: translateX(-10px);
      }
      50% {
        transform: translateX(50px);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }
`

export const Links = styled.div`
  max-width: 1150px;
  min-width: 736px;
  margin: auto;
  display: grid;
  /* TODO: открыть, когда надо будет открыть меню  */
  grid-template-columns: repeat(1, 5fr) repeat(1, 1fr) repeat(1, 5fr) repeat(1, 6fr) repeat(1, 3fr) repeat(1, 4fr) repeat(
      1,
      5fr
    );
    /* TODO: открыть, когда надо будет скрыть меню  */
    /* grid-template-columns: repeat(1, 2fr) repeat(1, 4fr) repeat(1, 6fr) repeat(1, 3fr) repeat(1, 4fr) repeat(
      1,
      2fr
    ); */
  justify-items: center;
  align-items: center;
  height: 100%;
`
export const BurgerMenuButtonWrapper = styled.div`
  max-width: 32px;
  min-height: 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const BurgerMenuButton = styled.div<{ active: boolean }>`
  background-color: ${props => (props.active ? 'transparent' : `${Colors.turquoise}`)};
  border-radius: 10px;
  width: 32px;
  height: 4px;
  transition: all 200ms linear;
  &:after,
  &:before {
    content: '';
    border-radius: 10px;
    width: 32px;
    height: 4px;
    background-color: ${Colors.turquoise};
    position: absolute;
    transition: all 200ms linear;
  }
  &:before {
    transform: ${({ active }) => (active ? 'rotate(45deg)' : 'translateY(-8px)')};
    background-color: ${({ active }) => (active ? `${Colors.red}` : `${Colors.turquoise}`)};
  }
  &:after {
    transform: ${({ active }) => (active ? 'rotate(-45deg)' : 'translateY(8px)')};
    background-color: ${({ active }) => (active ? `${Colors.red}` : `${Colors.turquoise}`)};
  }

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
    fill: rgb(103, 219, 202);
    width: 100%;
    height: 100%;
  }
`
export const LoadingIcon = styled(ReactSVG)`
  width: 120px;
  height: 110px;
  margin-left: 5px;
  animation: animate 2.5s linear infinite;

  @keyframes animate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  svg {
    fill: rgb(103, 219, 202);
    width: 100%;
    height: 100%;
  }
`

export const LinkContainer = styled.div<{ selected: boolean; order: number; active: boolean }>`
  margin: auto;
  order: ${({ order }) => order};

  & > a {
    color: ${({ selected, active }) => (active ? Colors.white : selected ? Colors.red : Colors.black)};
    font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
    text-decoration: none;
    font-size: 16px;
    text-align: center;
    pointer-events: ${({ active }) => (active ? 'none' : 'auto')};
    transition: all 150ms ease;
  }

  &:hover > a {
    color: ${({ active }) => (active ? `${Colors.white}` : `${Colors.red}`)};
  }
`

export const SideBarContainter = styled.div<{ active: boolean }>`
  position: absolute;
  top: ${props => (props.active ? '100px' : '-470px')};
  max-height: 470px;
  padding-top: 45px;
  padding-bottom: 55px;
  width: 100%;
  background-color: #ffffff;
  z-index: 11;
  transition: all 500ms ease-in-out;
`
export const SidebarLinks = styled.div`
  max-width: 1000px;
  margin: auto;
  max-height: 470px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`
export const SidebarPageName = styled.a`
  font-weight: bold;
  font-size: 35px;
`

export const SidebarLinkContainer = styled.div<{
  selected: boolean
  order: number
  active: boolean
  lineNumber: number
}>`
  align-self: flex-start;
  order: ${({ order }) => order};
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: ${({ lineNumber, active }) =>
    active ? `all ${lineNumber / 2 + 800}ms ease-in-out` : `opacity 200ms step-end, transform 200ms step-end`};
  transform: ${({ active }) => (active ? 'translateX(0px)' : 'translateX(-400px)')};
  margin-bottom: 40px;
  & > a {
    color: ${({ selected }) => (selected ? Colors.red : Colors.black)};
    font-weight: 'bold';
    text-decoration: none;
    text-align: center;
  }

  &:hover > a {
    color: ${Colors.red};
    cursor: pointer;
  }
`
